<template>
  <div class="mobile-app">
    <el-container>
      <el-header class="fixed-header">
        <el-button icon="el-icon-arrow-left" @click="goToHome" type="text" class="back-button"></el-button>
        <h1>备件领用入库</h1>
      </el-header>
      <el-main class="scrollable-main">
        <el-form label-width="80px" size="large" class="formStar">
          <el-form-item label="物料号" class="custom-form-item">
<!--            <el-input placeholder="请输入物料号" v-model="form.compnum" @input="updateCompname"></el-input>-->
            <el-autocomplete
                class="inline-input"
                v-model="form.compnum"
                :fetch-suggestions="querySearch"
                placeholder="请输入物料号"
                :trigger-on-focus="false"
                @input="updateCompname"
            ></el-autocomplete>
          </el-form-item>
          <el-form-item label="库位" class="custom-form-item">
<!--            <span style="font-size: 18px">{{form.location}}</span>-->
<!--            <el-input placeholder="请输入库位号" v-model="form.location" @input="updateCompname1"></el-input>-->
            <el-autocomplete
                class="inline-input"
                v-model="form.location"
                :fetch-suggestions="querySearch1"
                placeholder="请输入库位"
                :trigger-on-focus="false"
                @input="updateCompname1"
            ></el-autocomplete>
          </el-form-item>
          <el-form-item label="零件名" class="custom-form-item">
            <span style="font-size: 18px">{{form.compname}}</span>
          </el-form-item>
<!--          <el-form-item label="单价" class="custom-form-item">-->
<!--            <span style="font-size: 18px">{{form.price}}元</span>-->
<!--          </el-form-item>-->
          <el-form-item label="数量" class="custom-form-item">
            <el-input placeholder="请输入数量" v-model="form.num"></el-input>
          </el-form-item>
        </el-form>
        <el-row type="flex" justify="center">
          <el-button type="primary" @click="addNew" plain>新物料入库</el-button>
          <el-button type="primary" @click="save2" size="large">常规入库</el-button>
          <el-button type="primary" @click="save" size="large" style="margin-left: 30px">领用</el-button>
        </el-row>
        <el-divider></el-divider>
        <span>查询备件</span>
        <div style="margin: 10px 0">
          <el-input style="width: 200px" placeholder="请输入物料号" suffix-icon="el-icon-search" v-model="compnum"></el-input>
          <el-input style="width: 200px" placeholder="请输入库位" suffix-icon="el-icon-search" v-model="location"></el-input>
          <el-input style="width: 200px" placeholder="请输入名称" suffix-icon="el-icon-search" v-model="name"></el-input>

          <el-button class="ml-5" type="primary" @click="load">搜索</el-button>
          <el-button type="warning" @click="reset">重置</el-button>

        </div>
        <el-table :data="tableData" border stripe :header-cell-class-name="'headerBg'">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="compnum" label="备件号"></el-table-column>
          <el-table-column prop="location" label="库位"></el-table-column>
          <el-table-column prop="name" label="名称"></el-table-column>
<!--          <el-table-column-->
<!--              prop="price"-->
<!--              label="单价(元)"-->
<!--              :formatter="formatPrice"-->
<!--          ></el-table-column>-->
          <el-table-column prop="sum" label="库存数(个)"></el-table-column>
          <el-table-column label="图片">
            <template v-slot="scope">
              <el-image
                  v-if="scope.row.imageUrl"
                  style="width: 100px; height: 100px"
                  :src="scope.row.imageUrl"
                  :preview-src-list="[scope.row.imageUrl]"
              ></el-image>
            </template>
          </el-table-column>
        </el-table>
        <div style="padding: 10px 0">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-sizes="[2, 5, 10, 20]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
          </el-pagination>
        </div>
      </el-main>
      <!-- 底部导航栏 -->
      <el-footer class="fixed-footer">
        <el-row type="flex" justify="center">
          <el-col :span="8" class="footer-item">
            <router-link to="/mobile">首页</router-link>
          </el-col>
          <el-col :span="8" class="footer-item">
            <router-link to="/mequipment">设备知识库</router-link>
          </el-col>
          <el-col :span="8" class="footer-item">
            <router-link to="/mperson">个人信息</router-link>
          </el-col>
        </el-row>
      </el-footer>
    </el-container>

    <el-dialog title="信息" :visible.sync="dialogFormVisible" width="100%" >
      <el-form label-width="80px" size="small">
        <el-form-item label="ID" v-if="false">
          <el-input v-model="form2.id" disabled autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="物料号">
          <el-input v-model="form2.compnum" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="库位">
          <el-input v-model="form2.location" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="完成描述">
          <el-input placeholder="请输入名称品牌型号材质"  v-model="form2.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="到货数">
          <el-input v-model="form2.sum" autocomplete="off"></el-input>
        </el-form-item>
<!--        <el-form-item label="图片">-->
<!--          <el-image-->
<!--              style="width: 100px; height: 100px"-->
<!--              :src="form.imageUrl"-->
<!--              :preview-src-list="[form.imageUrl]">-->
<!--          </el-image>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="上传图片" label-width="100px">-->
<!--          <el-upload class="avatar-uploader" action="http://39.106.92.106:9090/files/upload" :show-file-list="true"-->
<!--                     :on-success="handleAvatarSuccess" style="display: inline-block" :multiple="false">-->
<!--            <el-button type="flex" justify="center">上传图片 </el-button>-->
<!--          </el-upload>-->
<!--        </el-form-item>-->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save3">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "MComponent",
  data() {
    return {
      form: {},
      form2: {},
      tableData: [],
      pageNum: 1,
      pageSize: 10,
      name: "",
      compnum: "",
      location: "",
      userId: null,
      dialogFormVisible: false,
      users: [],
      components: [],
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
    }
  },
  created() {
    this.load()
  },
  methods: {
    // 获取部门、线体和设备数据
    load() {
      this.request.get("/component/page", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          name: this.name,
          compnum: this.compnum,
          location: this.location,
          userId: this.userId
        }
      }).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
      this.request.get("/component").then(res => {
        this.components = res.data;
      });
      this.request.get("/user/"+this.user.id).then(res => {
        this.users = res.data;
      });
    },
    // 返回首页
    goToHome() {
      this.$router.push('/mobile');
    },
    updateCompname() {
      const component = this.components.find(item => item.compnum === this.form.compnum);
      this.form.compname = component ? component.name : '';
      this.form.price = component ? component.price : '';
      this.form.location = component ? component.location : '';
    },
    updateCompname1() {
      const component = this.components.find(item => item.location === this.form.location);
      this.form.compname = component ? component.name : '';
      this.form.price = component ? component.price : '';
      this.form.compnum = component ? component.compnum : '';
    },
    querySearch(queryString, cb) {
      var results = queryString
          ? this.components
              .filter(this.createFilter(queryString))
              .map(component => ({ value: component.compnum }))
          : [];
      cb(results);
    },
    createFilter(queryString) {
      return (component) => {
        return (component.compnum.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    querySearch1(queryString, cb) {
      var results = queryString
          ? this.components
              .filter(this.createFilter1(queryString))
              .map(component => ({ value: component.location }))
          : [];
      cb(results);
    },
    createFilter1(queryString) {
      return (component) => {
        return (component.location.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    addNew(){
      this.dialogFormVisible = true;
    },
    handleAvatarSuccess(res) {
      console.log("success"+res.data)
      this.form.avatarUrl = res.data
    },
    // 保存报修信息
    save() {
      const recordTime = new Date().toLocaleString('zh-CN', {
        timeZone: 'Asia/Shanghai', // 设置为中国标准时间
        hour12: false, // 24小时制
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }).replace(/\//g, '-').replace(',', '');

      // 将时间格式化为 "YYYY-MM-DD HH:MM:SS"
      const formattedRecordTime = recordTime.replace(/(\d{4})-(\d{2})-(\d{2})/, '$1-$2-$3').replace(/(\d{2}):(\d{2}):(\d{2})/, '$1:$2:$3');

      console.log(formattedRecordTime); // 输出格式为 "YYYY-MM-DD HH:MM:SS"
      const formData = {
        sum: this.form.num,
        compnum: this.form.compnum,
      };
      const formData2 = {
        num: this.form.num,
        compnum: this.form.compnum,
        name: this.form.compname,
        location: this.form.location,
        userId: this.user.id,
        userName: this.users.nickname,
        takeTime: formattedRecordTime,
      };
      if (this.form.compnum && this.form.location && this.form.num) {
        this.request.post("/component/updateSum", formData).then(res => {
          if (res.code === '200') {
            this.$message.success("领用成功")
            this.request.post("/componentUpdate/declineSum", formData2)
            this.form = {};
            this.load();
          } else {
            this.$message.error("领用失败")
          }
        })
      }else{
        this.$message.error("不可为空")
      }
    },
    save2() {
      const recordTime = new Date().toLocaleString('zh-CN', {
        timeZone: 'Asia/Shanghai', // 设置为中国标准时间
        hour12: false, // 24小时制
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }).replace(/\//g, '-').replace(',', '');

      // 将时间格式化为 "YYYY-MM-DD HH:MM:SS"
      const formattedRecordTime = recordTime.replace(/(\d{4})-(\d{2})-(\d{2})/, '$1-$2-$3').replace(/(\d{2}):(\d{2}):(\d{2})/, '$1:$2:$3');

      console.log(formattedRecordTime); // 输出格式为 "YYYY-MM-DD HH:MM:SS"
      const formData = {
        sum: this.form.num,
        compnum: this.form.compnum,
      };
      const formData2 = {
        num: this.form.num,
        compnum: this.form.compnum,
        name: this.form.compname,
        location: this.form.location,
        userId: this.user.id,
        userName: this.users.nickname,
        takeTime: formattedRecordTime,
      };
      if (this.form.compnum && this.form.location && this.form.num) {
        this.request.post("/component/addSum", formData).then(res => {
          if (res.code === '200') {
            this.$message.success("入库成功")
            this.request.post("/componentUpdate/addSum", formData2)
            this.form = {};
            this.load();
          } else {
            this.$message.error("入库失败")
          }
        })
      }else{
        this.$message.error("不可为空")
      }
    },
    save3() {
      this.request.post("/component", this.form2).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.dialogFormVisible = false
          this.load()
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
    formatPrice(row, column, value) {
      return Math.floor(value); // 取整
    },
    reset() {
      this.name = ""
      this.location = ""
      this.compnum = ""
      this.load()
    },
  },
};
</script>

<style scoped>
.mobile-app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.fixed-header, .fixed-footer {
  background-color: rgb(38, 52, 69);
  color: #fff;
  /*  text-align: center;!**!*/
  padding: 10px;
  position: fixed;
  width: 100%;
  z-index: 1000; /* 确保在顶部 */
}

.fixed-header {
  display: flex;
  align-items: center;
  justify-content: center; /* 居中对齐 */
  top: 0;
}

.fixed-footer {
  bottom: 0;
}

.scrollable-main {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  margin-top: 60px; /* 保证 el-main 在 el-header 下面 */
  margin-bottom: 60px; /* 保证 el-main 在 el-footer 上面 */
}

.footer-item {
  line-height: 40px;
  text-align: center;
}

.footer-item a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.footer-item a:hover {
  text-decoration: underline;
}

.formStar {
  font-size: 20px;
}


</style>
